// Import Library
import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import plus18 from '../../assets/images/welcome/plus18.png';

// import context
import { Context as UserContext } from '../../dataStore/userAccessContext';

const title = "Por favor lee esta advertencia antes de continuar";
const paragraph1 = (
    <p>
        Al acceder a este sitio web, declaras ser mayor de edad y aceptar los{' '}
        <Link to="/policy">
            <span className="theme-color fw-bold text-white fs-6">Términos de uso</span>
        </Link>
        , exonerando a Belbom de cualquier responsabilidad derivada del uso del sitio.
    </p>
)

const btnTextAccept = "Aceptar";
const btnTextCancel = "Cancelar";

const imageStyle = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '150px',
    marginBottom: '20px'
}

const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
}

const Welcome = () => {

    const { setContextWelcome } = useContext(UserContext);

    const closePopup = () => {
        setContextWelcome(false);
    }

    const closeWindow = (event) => {
        event.preventDefault();
        window.location.href = 'https://www.google.com';
    }

    return (
        <div className="modal" style={modalStyle}>
            <div className="login-section padding-tb">
                <div className=" container">
                    <div className="account-wrapper">
                        <img src={plus18} alt="Mayores de 18" style={imageStyle} />
                        <h3>{title}</h3>
                        {paragraph1}
                        <form className="account-form">
                            <div className="form-group">
                                <button onClick={closePopup} className="d-block lab-btn"><span>{btnTextAccept}</span></button>
                                <button onClick={closeWindow} className="d-block lab-btn"><span>{btnTextCancel}</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome