// Import Library
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { differenceInYears, parse, format } from 'date-fns';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import "photoswipe/style.css";

// Import Component
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/headertwo";
import FilterSearch from "../component/sidebar/filtersearch";
import LikeMember from "../component/sidebar/like-member";
import Loading from '../component/loading/loading';

// Import Web Api
import externalServices from '../dataservices/externalServices'
import posterServices from '../dataservices/posterServices'

// Import Hooks
import useGeolocation from '../hooks/useGeolocation';

// Import Animation
import animation from "../assets/animation.json";

// Import Config
import config from "../config/index";

const currentDate = new Date();

const textWhatsapp = 'Hola, acabo de ver tu anuncio en Belbom y me gustaría contactarme contigo'
const title = "¡Ups!";
const desc1 = "Tu búsqueda no ha dado ningún resultado.";
const desc2 = "Selecciona otro criterio de busqueda"

const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const imgStyle = {
    width: '50%',
    height: 'auto',
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%'
}

const galleryStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '8px'
}

const linkStyle = {
    display: 'block',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    padding: '4px'
}

const photosStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px'
}

const ProfilePage = () => {

    const location = useLocation();
    const geolocation = useGeolocation()
    const queryParams = new URLSearchParams(location.search);
    const guidPublisher = queryParams.get('GuidPublisher');
    const [addressGoogle, setAddressGoogle] = useState(null);
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchStartValues = async () => {
        setLoading(true);
        const responseGetProfile = await posterServices.getProfile(guidPublisher);
        if (!responseGetProfile.code) {
            setProfile(responseGetProfile)
            const responseGetAddressGoogle = await externalServices.getAddressGoogle(responseGetProfile.coordinates.latitude, responseGetProfile.coordinates.longitude);
            if (responseGetAddressGoogle.results && responseGetAddressGoogle.results.length > 0) {
                setAddressGoogle(responseGetAddressGoogle.results[0].formatted_address);
            }
        }
        setLoading(false);
    }

    const getProfilePhoto = (photos) => {
        const item = Array.isArray(photos) ? photos.find(e => e.isProfilePhoto) : null;
        return item
            ? `${process.env.REACT_APP_URL_MEDIA}${item.path}/${item.fileName}`
            : `${process.env.PUBLIC_URL}/assets/images/logo/fondo_xugars_2.png`;
    }

    const getContainerStyle = (photos) => {
        const hasProfilePhoto = Array.isArray(photos) && photos.some(e => e.isProfilePhoto);
        return hasProfilePhoto ? {} : containerStyle;
    }

    const getImgStyle = (photos) => {
        const hasProfilePhoto = Array.isArray(photos) && photos.some(e => e.isProfilePhoto);
        return hasProfilePhoto ? {} : imgStyle;
    }

    useEffect(() => {
        fetchStartValues();
    }, [guidPublisher])

    useEffect(() => {
        if (profile && !loading) {
            let lightbox = new PhotoSwipeLightbox({
                gallery: "#" + "my-test-gallery",
                children: "a",
                pswpModule: () => import("photoswipe"),
                padding: { top: 30, bottom: 30, left: 30, right: 30 },
            });
            lightbox.init();
            return () => {
                lightbox.destroy();
                lightbox = null;
            };
        }
    }, [profile, loading])

    return (
        <Fragment>
            {loading || geolocation.loading || !geolocation?.geolocation?.country_code3 ? (
                <Loading animation={animation} />
            ) : profile ? (
                <Fragment>
                    <HeaderTwo country={geolocation.geolocation.country} />
                    <section className="profile-section padding-tb">
                        <div className="container">
                            <div className="section-wrapper">
                                <div className="member-profile">
                                    <div className="profile-item">
                                        <div className="profile-cover">
                                            <img src="assets/images/profile/cover.jpg" alt="cover-pic" />
                                        </div>
                                        <div className="profile-information">
                                            <div className="profile-pic" style={getContainerStyle(profile.photos)} >
                                                <img
                                                    src={getProfilePhoto(profile.photos)}
                                                    alt="logo"
                                                    style={getImgStyle(profile.photos)}
                                                />
                                            </div>
                                            <div className="profile-name">
                                                <h4>{profile.alias}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile-details">
                                        <nav className="profile-nav">
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button className="nav-link active" id="nav-profile-tab" data-bs-toggle="tab"
                                                    data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                                    aria-selected="true">Perfil</button>
                                                <button className="nav-link" id="nav-friends-tab" data-bs-toggle="tab"
                                                    data-bs-target="#friends" type="button" role="tab" aria-controls="friends"
                                                    aria-selected="false">Caracteristicas</button>
                                                <button className="nav-link" id="nav-groups-tab" data-bs-toggle="tab"
                                                    data-bs-target="#groups" type="button" role="tab" aria-controls="groups"
                                                    aria-selected="false">Disponibilidad</button>
                                                <button className="nav-link" id="nav-ativity-tab" data-bs-toggle="tab"
                                                    data-bs-target="#activity" type="button" role="tab" aria-controls="activity"
                                                    aria-selected="false">Ubicación</button>
                                                <button className="nav-link" id="nav-photos-tab" data-bs-toggle="tab"
                                                    data-bs-target="#photos" type="button" role="tab" aria-controls="photos"
                                                    aria-selected="false">Fotos</button>
                                            </div>
                                        </nav>

                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane activity-page fade show active" id="profile" role="tabpanel">
                                                <div>
                                                    <div className="row">
                                                        <div className="col-xl-8">
                                                            <article>
                                                                <div className="info-card mb-20">
                                                                    <div className="info-card-title">
                                                                        <h6>Información Básica</h6>
                                                                    </div>
                                                                    <div className="info-card-content">
                                                                        <ul className="info-list">
                                                                            <li>
                                                                                <p className="info-name">Nombre</p>
                                                                                <p className="info-details">{profile.alias}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="info-name">Categoria</p>
                                                                                <p className="info-details">{profile.nameCategory}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="info-name">Nacionalidad</p>
                                                                                <p className="info-details">{profile.nameNationality}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="info-name">Teléfono</p>
                                                                                <p onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    window.location.href = `tel:${profile.phone}`;
                                                                                }}
                                                                                    className="info-details"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                >
                                                                                    {`${profile.internationalDiallingCode}${profile.phone}`}</p>
                                                                            </li>
                                                                            {profile.whatsapp && (
                                                                                <li>
                                                                                    <p className="info-name">Whatsapp</p>
                                                                                    <p onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        window.open(`${config.url.whatsapp}/${profile.internationalDiallingCode}${profile.phone}?text=${encodeURIComponent(textWhatsapp)}`, '_blank');
                                                                                    }}
                                                                                        className="info-details"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    >
                                                                                        {`${profile.internationalDiallingCode}${profile.phone}`}</p>
                                                                                </li>
                                                                            )}
                                                                            <li>
                                                                                <p className="info-name">Edad</p>
                                                                                <p className="info-details">{`${differenceInYears(currentDate, profile.birthDate)} años`}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="info-name">Altura</p>
                                                                                <p className="info-details">{profile.nameHeight}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="info-name">Contextura</p>
                                                                                <p className="info-details">{profile.nameWeight}</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="info-card mb-20">
                                                                    <div className="info-card-title">
                                                                        <h6>Presentación</h6>
                                                                    </div>
                                                                    <div className="info-card-content">
                                                                        <p>{profile.presentation}</p>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                        <div className="col-xl-4">
                                                            <aside className="mt-5 mt-xl-0">
                                                                <FilterSearch countryCode={geolocation.geolocation.country_code3} />
                                                                <LikeMember guidPublisher={profile.guidPublisher} countryCode={geolocation.geolocation.country_code3} />
                                                            </aside>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="friends" role="tabpanel" aria-labelledby="nav-friends-tab">
                                                <div>
                                                    <div className="row">
                                                        <div className="col-xl-8">
                                                            <article>
                                                                {profile.preferences.map((value, index) => (
                                                                    <div className="post-item mb-20" key={index}>
                                                                        <div className="post-content">
                                                                            <div className="post-author">
                                                                                <div className="post-author-inner">
                                                                                    <div className="author-thumb">
                                                                                        <img src="assets/images/about/04.jpg" alt="img" />
                                                                                    </div>
                                                                                    <div className="author-details">
                                                                                        <h6>{value.nameCatalog}</h6>
                                                                                        <p className="w-100 mb-0 mt-2">{`${value.nameDetail}`}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </article>
                                                        </div>
                                                        <div className="col-xl-4">
                                                            <aside className="mt-5 mt-xl-0">
                                                                <FilterSearch countryCode={geolocation.geolocation.country_code3} />
                                                                <LikeMember guidPublisher={profile.guidPublisher} countryCode={geolocation.geolocation.country_code3} />
                                                            </aside>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="groups" role="tabpanel" aria-labelledby="nav-groups-tab">
                                                <div className="group-section style2">
                                                    <div className="row">
                                                        <div className="col-xl-8">
                                                            <article>
                                                                {profile.availabilities.map((value, index) => (
                                                                    <div className="post-item mb-20" key={index}>
                                                                        <div className="post-content">
                                                                            <div className="post-author">
                                                                                <div className="post-author-inner">
                                                                                    <div className="author-thumb">
                                                                                        <img src="assets/images/about/05.jpg" alt="img" />
                                                                                    </div>
                                                                                    <div className="author-details">
                                                                                        <h6>{value.nameDetail}</h6>
                                                                                        <p className="w-100 mb-0 mt-2">
                                                                                            {`${format(parse(value.startTime, 'HH:mm:ss', new Date()), 'HH:mm')} - ${format(parse(value.endTime, 'HH:mm:ss', new Date()), 'HH:mm')}`}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </article>
                                                        </div>

                                                        <div className="col-xl-4">
                                                            <aside className="mt-5 mt-xl-0">
                                                                <FilterSearch countryCode={geolocation.geolocation.country_code3} />
                                                                <LikeMember guidPublisher={profile.guidPublisher} countryCode={geolocation.geolocation.country_code3} />
                                                            </aside>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="nav-activity-tab">
                                                <div className="group-section style2">
                                                    <div className="row">
                                                        <div className="col-xl-8">
                                                            <article>
                                                                <div className="row g-4 row-cols-1">
                                                                    <div className="col">
                                                                        <div className="group-item lab-item">
                                                                            <div className="lab-inner">
                                                                                <div className="lab-thumb" style={{ position: 'relative', width: '100%', paddingBottom: '56.25%', height: 0 }}>
                                                                                    <iframe
                                                                                        src={`https://maps.google.com/maps?q=${profile.coordinates.latitude.toString()},${profile.coordinates.longitude.toString()}&hl=es;&output=embed`}
                                                                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0 }}
                                                                                        allowFullScreen
                                                                                        aria-hidden="false"
                                                                                        tabIndex="0"
                                                                                        title="Google Map"
                                                                                    ></iframe>
                                                                                </div>
                                                                                <div className="lab-content" style={{ width: '100%', paddingBlock: "20px" }}>
                                                                                    <h6>{`${profile.geographicLocation1Name} - ${profile.geographicLocation2Name} - ${addressGoogle}`}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                        <div className="col-xl-4">
                                                            <aside className="mt-5 mt-xl-0">
                                                                <FilterSearch countryCode={geolocation.geolocation.country_code3} />
                                                                <LikeMember guidPublisher={profile.guidPublisher} countryCode={geolocation.geolocation.country_code3} />
                                                            </aside>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade" id="photos" role="tabpanel" aria-labelledby="nav-photos-tab">
                                                <div className="row">
                                                    <div className="col-xl-8">
                                                        <div className="row g-3 g-lg-4 justify-content-center row-cols-2" id="my-test-gallery" style={galleryStyle}>
                                                            {profile.photos.map((value, index) => (
                                                                <a
                                                                    href={`${process.env.REACT_APP_URL_MEDIA}${value.path}/${value.fileName}`}
                                                                    data-pswp-width={value.width}
                                                                    data-pswp-height={value.height}
                                                                    key={index}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    style={linkStyle}
                                                                >
                                                                    <img
                                                                        src={`${process.env.REACT_APP_URL_MEDIA}${value.path}/${value.fileName}`}
                                                                        alt=""
                                                                        style={photosStyle}
                                                                    />
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <aside className="mt-5 mt-xl-0">
                                                            <FilterSearch countryCode={geolocation.geolocation.country_code3} />
                                                            <LikeMember guidPublisher={profile.guidPublisher} countryCode={geolocation.geolocation.country_code3} />
                                                        </aside>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </Fragment>
            ) : (
                <Fragment>
                    <HeaderTwo country={geolocation.geolocation.country} />
                    <section className="profile-section padding-tb">
                        <div className="container">
                            <div className="section-header" style={{ paddingTop: "100px" }}>
                                <h2 >{title}</h2>
                                <p>{desc1}</p>
                                <span>{desc2}</span>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </Fragment>
            )}
        </Fragment>
    );
}

export default ProfilePage;