// Import Library
import React, { useContext, useEffect, Fragment } from "react";

// Import Context
import { Context as UserContext } from '../dataStore/userAccessContext';

// Import Component
import HeaderTwo from '../component/layout/headertwo';
import BannerTwo from '../component/section/bannertwo';
import AboutTwo from '../component/section/abouttwo';
import AboutThree from '../component/section/aboutthree';
import Top from '../component/section/top';
import City from '../component/section/city';
import Map from "../component/section/map";
import Footer from '../component/layout/footer';
import Loading from '../component/loading/loading';
import Welcome from '../component/welcome/welcome';

// Import Hooks
import useGeolocation from '../hooks/useGeolocation';

// Import Animation
import animation from "../assets/animation.json";

const HomeTwo = () => {

    const { state, setContextWelcome } = useContext(UserContext);
    const { showWelcome } = state;
    const geolocation = useGeolocation()

    useEffect(() => {
        if (showWelcome) {
            setContextWelcome(true);
        }
    }, [])

    if (geolocation.loading && !geolocation?.geolocation?.country_code3) {
        return <Loading animation={animation} />;
    }

    return (
        <Fragment>
            {showWelcome && <Welcome />}
            <Fragment>
                <HeaderTwo country={geolocation.geolocation.country} />
                <BannerTwo />
                <AboutTwo countryCode={geolocation.geolocation.country_code3} />
                <Top countryCode={geolocation.geolocation.country_code3} />
                <City countryCode={geolocation.geolocation.country_code3} country={geolocation.geolocation.country} />
                <Map countryCode={geolocation.geolocation.country_code3} />
                <AboutThree />
                <Footer />
            </Fragment>
        </Fragment>
    )
}

export default HomeTwo