// Import Library
import { Fragment } from "react";
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/headertwo";

// Import Component
import Loading from '../component/loading/loading';

// Import Hooks
import useGeolocation from '../hooks/useGeolocation';

// Import Animation
import animation from "../assets/animation.json";

const sectionStyle = {
    paddingTop: '200px'
}

const Policy = () => {

    const geolocation = useGeolocation()

    if (geolocation.loading && !geolocation?.geolocation?.country_code3) {
        return <Loading animation={animation} />;
    }

    return (
        <Fragment>
            <HeaderTwo country={geolocation.geolocation.country} />
            <section className="terms-section padding-tb" style={sectionStyle}>
                <div className="container">
                    <div className="terms-content">
                        <div className="terms-header">
                            <h4>Condiciones de uso &amp; Responsabilidad</h4>
                            <p><span className="theme-color fw-bold">Última actualización:</span> 24 Septiembre, 2024</p>
                        </div>
                        <div className="terms-text">
                            <p className="mb-4">Al utilizar el sitio, usted acepta y se compromete a respetar estas Condiciones de uso, que Belbom puede, en cualquier momento, cambiar, integrar o modificar total o parcialmente y con la máxima discreción, en particular si es necesario por razones de de carácter legal o técnico, o incluso como consecuencia de cambios en los servicios ofrecidos y en el diseño del Sitio Belbom comunicará a los Usuarios, con un aviso específico, los cambios realizados en las Condiciones de Uso. Sin embargo, le sugerimos que consulte periódicamente esta página para comprobar si hay cambios.</p>
                            <p className="mb-4">Belbom puede cambiar, suspender o discontinuar la oferta de sus servicios y la disponibilidad de cualquier contenido del Sitio, la base de datos o cada artículo en cualquier momento. Belbom también puede, sin previo aviso, imponer límites a ciertos servicios o prohibir el acceso de un Usuario a todo el Sitio o a algunas partes del mismo, por razones técnicas, por razones de seguridad, para evitar el acceso no autorizado al Sitio y la pérdida o destrucción, de datos, o si cree, a su entera discreción, que el Usuario ha violado estos Términos de Uso o cualquier ley o reglamento, o en caso de terminación del servicio prestado.</p>
                            <p className="mb-4">A menos que se indique lo contrario en estos Términos de uso, el Sitio, incluido, a modo de ejemplo, su estructura técnica, todos los textos, imágenes, marcas comerciales, logotipos y cualquier otra cosa contenida dentro del Sitio mismo ("Contenido del Sitio") son propiedad exclusiva de Belbom, todos los derechos reservados. Por tanto, el Contenido del Sitio no puede ser utilizado por terceros sin la autorización previa por escrito del propietario.</p>
                            <p className="mb-4">Todos los contenidos insertados por los Usuarios en el Sitio pasan a ser propiedad de Belbom, sin perjuicio de los derechos del Usuario en relación con sus datos personales, proporcionados al registrarse en el Sitio y/o durante el uso del mismo.</p>
                            <p className="mb-4">Belbom podrá ceder, transferir y/o ceder libremente a terceros los derechos y obligaciones establecidos en estos Términos de Uso.</p>
                            <p className="mb-4">Los usuarios aceptan y reconocen que no se establece ninguna relación laboral, ni contrato de agencia, ni empresa conjunta o sociedad entre ellos y Belbom como resultado del uso del Sitio y la consecuente aceptación de estas Condiciones de Uso.</p>
                            <ol>
                                <li>
                                    <h5>Exclusiones de responsabilidad</h5>
                                    <p>Belbom es un servicio proporcionado al usuario de forma gratuita "tal cual", sin ningún tipo de garantía expresa o implícita ni ningún tipo de asistencia o soporte técnico.</p>
                                    <p>Belbom no garantiza la veracidad y exactitud de la información y el contenido presentes en el Sitio. Además, Belbom no es responsable de los contenidos insertados por sus Usuarios a través de las áreas interactivas presentes en el Sitio.</p>
                                    <p>Cada Usuario es exclusiva y personalmente responsable de lo insertado en el Sitio y Belbom procederá, de acuerdo con las disposiciones legales aplicables, a eliminar y/o bloquear inmediatamente cualquier contenido ilícito, así como a colaborar - si así lo solicita - con el autoridades competentes.</p>
                                    <p>El Sitio también contiene enlaces a sitios de terceros. Los sitios enlazados no están bajo el control de Belbom que, por tanto, no asume ninguna responsabilidad por sus contenidos.</p>
                                </li>
                                <li>
                                    <h5>Actividades no permitidas</h5>
                                    <p>Los contenidos e información presentes en el portal (incluidos, entre otros, mensajes, datos, información, texto, música, audio, fotografías, gráficos, vídeos, mapas, iconos, software, códigos u otro material), así como los La infraestructura utilizada para proporcionar dicho contenido e información son propiedad de Belbom. Usted acepta no modificar, copiar, distribuir, transmitir, mostrar, realizar, reproducir, publicar, otorgar licencias, crear trabajos derivados, transferir, vender o revender cualquier información, software, productos o servicios obtenidos a través del portal. Además, el Usuario acepta:</p>
                                    <ol>
                                        <li>no utilizar el portal ni sus contenidos con fines comerciales;</li>
                                        <li>no acceder, monitorear, copiar los contenidos o información del portal utilizando robots, spiders, scrapers u otros medios automáticos o procesos manuales para cualquier propósito sin el consentimiento expreso por escrito de Belbom;</li>
                                        <li>violar las restricciones en los encabezados de exclusión de robots en el portal o eludir otras medidas tomadas para prevenir o limitar el acceso al portal;</li>
                                        <li>tomar medidas para imponer o intentar imponer una carga desproporcionada o irrazonable, a discreción de Belbom, en la infraestructura del portal;</li>
                                        <li>vincular profundamente cualquier parte del portal para cualquier propósito sin el consentimiento expreso por escrito de Belbom;</li>
                                        <li>enmarcar, reflejar o incorporar de otro modo cualquier parte del Portal en otros sitios web sin el consentimiento previo por escrito de Belbom;</li>
                                        <li>intentar modificar, traducir, adaptar, cambiar, descompilar, desensamblar o aplicar ingeniería inversa a los programas de software utilizados por Belbom en relación con el portal o los servicios.</li>
                                    </ol>
                                    <p>Al registrarse en el Sitio, usted acepta y garantiza expresamente que utilizará los servicios de Belbom en pleno cumplimiento de estas Condiciones de Uso y que se abstendrá de violar, con su comportamiento, los derechos de terceros y todas las leyes aplicables. Además, acepta no publicar ni distribuir ningún contenido o material cubierto por derechos de autor u otros derechos de propiedad intelectual, sin el consentimiento expreso del propietario correspondiente. También se compromete a insertar y/o publicar en el Sitio contenidos de los que puede disponer libre y legalmente y que se refieren exclusivamente a usted.</p>
                                    <p>A la luz de estos compromisos, usted acepta explícitamente y acepta indemnizar a Belbom y al propietario del Sitio de cualquier responsabilidad hacia cualquier persona que surja de su uso del Sitio que no cumpla con estas Condiciones de Uso o con la ley. También declara que tiene al menos dieciocho años de edad y, por lo tanto, es legalmente capaz de utilizar este Sitio y los servicios aquí ofrecidos en pleno cumplimiento de todas estas Condiciones de Uso.</p>
                                    <p>Le advertimos que el Sitio contiene enlaces a sitios de terceros, administrados por proveedores independientes o incluso revendedores. Los servicios ofrecidos a través de estos sitios, así como el contenido de los mismos, pueden estar sujetos a cargos. Es su exclusiva responsabilidad realizar todas las consultas apropiadas antes de continuar con cualquier transacción, para determinar si se le cobrará o no por utilizar dichos servicios. Cuando Belbom proporcione, en el Sitio, detalles sobre los precios de los servicios ofrecidos por sitios de terceros, se considerarán indicados únicamente con fines informativos y, en cualquier caso, tendrán carácter meramente indicativo. Belbom no asume ninguna responsabilidad por la exactitud de dicha información, ni de ninguna manera puede asociarse con dichos sitios de terceros y su contenido.</p>
                                </li>
                                <li>
                                    <h5>Notificación y eliminación</h5>
                                    <p>Belbom, en cumplimiento de sus obligaciones legales, ha establecido un procedimiento para informar y eliminar Contenido del Usuario o Contenido del Sitio que viole los derechos de terceros. Si tiene una objeción o queja con respecto a dicho Contenido, por ejemplo, si cree que el material y el Contenido publicado en este Sitio infringe sus derechos de autor, comuníquese con nosotros sin dudarlo a través del correo electrónico <span className="fw-bold text-white fs-6">contact@belbom.com</span></p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    )
}

export default Policy