export const cardStyle = {
    height: '100%',
    cursor: 'pointer',
}

export const labInnerStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '100%'
}

export const labThumbStyle = {
    width: '200px',
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
}

export const swiperStyle = {
    height: '100%',
    width: '100%',
}

export const swiperSlideStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
}

export const imgAvailableStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block'
}

export const imgDefaultStyle = {
    width: '75%',
    height: '75%',
    objectFit: 'contain',
    display: 'block'
}

export const slideCounterStyle = {
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    color: 'white',
    backgroundColor: 'rgba(13, 54, 135, 0.5)',
    padding: '2.5px 5px',
    borderRadius: '5px',
    fontSize: '10px',
    zIndex: 10,
    fontWeight: 'bold',
}

export const labContentStyle = {
    width: '100%'
}

export const blockStyle = {
    display: 'block'
}

export const iconStyle = {
    marginRight: '5px'
}

export const topLabelStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: 'white',
    padding: '5px 10px',
    borderRadius: '5px',
    fontWeight: 'bold',
    zIndex: 10,
    fontSize: '30px'
}

export const multiLineTruncateStyle = {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}