import http from "../comunication/http-common";

const getGeolocation = async () => {
    const url = process.env.REACT_APP_GEOLOCATION_URL;
    return await http.get(`${url}`)
        .then((result) => {
            const geolocationInfo = {
                country_code3: result.data.country_code3,
                country: result.data.country
            }
            localStorage.setItem('geolocation', JSON.stringify(geolocationInfo));
            return geolocationInfo;
        })
        .catch((error) => {
            return { "code": error.response?.data?.type || error.name || "UnknownErrorCode", "message": error.response?.data?.title || error.message || "UnknownErrorMessage", "status": error.response?.status }
        });
};

const getAddressGoogle = async (latitude, longitude) => {
    const url = process.env.REACT_APP_URL_GOOGLE_MAPS;
    return await http.get(url, {
        params: {
            latlng: `${latitude.toString()},${longitude.toString()}`,
            key: process.env.REACT_APP_KEY_GOOGLE_MAPS
        }
    })
        .then((result) => result.data)
        .catch((error) => {
            return { "code": error.response?.data?.type || error.name || "UnknownErrorCode", "message": error.response?.data?.title || error.message || "UnknownErrorMessage", "status": error.response?.status }
        });
};

export default { getGeolocation, getAddressGoogle }