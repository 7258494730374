const title = <h2>Encuentra <span className="theme-color">fantasía</span> y <span className="theme-color">antiestrés</span></h2>;

let BannerTwoContentList = [
    {
        iconName: 'icofont-heart-alt',
        text: 'Amigable, eficiente y simple',
    },
    {
        iconName: 'icofont-heart-alt',
        text: 'Un sitio acorde a tus necesidades',
    },
    {
        iconName: 'icofont-heart-alt',
        text: 'Encuentra tu bell@ bombón',
    },
    {
        iconName: 'icofont-heart-alt',
        text: 'Absolutamente confiable',
    },
    {
        iconName: 'icofont-heart-alt',
        text: 'Comunidad para mayores de 18 años',
    },
]

const BannerTwo = () => {
    return (
        <section className="banner-section style2 bgimg" style={{ backgroundImage: "url(/assets/images/banner/bg2.jpg)" }}>
            <div className="container">
                <div className="section-wrapper">
                    <div className="row align-items-end">
                        <div className="col-lg-6">
                            <div className="banner-content">
                                <div className="intro-form">
                                    {title}
                                    <ul>
                                        {BannerTwoContentList.map((val, index) => (
                                            <li key={index}><i className={val.iconName}></i>{val.text}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="banner-thumb">
                                <img src="assets/images/banner/01.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BannerTwo;