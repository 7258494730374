// Import Library
import { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';

// Import Component
import SelectGeneric from "../select/select-generic";

// Import Web Api
import posterServices from "../../dataservices/posterServices";

const title = "Filtrar Miembro Búsqueda";
const desc = "Tu cita perfecta está a solo un clic.";
const btnText = "Buscar";

const FilterSearch = (props) => {

    const navigate = useNavigate();
    const { countryCode } = props;
    const [loadingFilter, setLoadingFilter] = useState({ catalogNationality: [], catalogCategory: [], catalogHeight: [], ages: [] });
    const [searchFilter, setSearchFilter] = useState({ nationality: null, category: null, height: null, ageStart: null, ageEnd: null });
    const [showAlert, setShowAlert] = useState(false);
    const timerRef = useRef(null);

    const fetchStartValues = async () => {
        const getLoadingFilter = await posterServices.getLoadingFilter(countryCode);
        if (!getLoadingFilter.code &&
            getLoadingFilter.catalogNationality.length > 0 &&
            getLoadingFilter.catalogCategory.length > 0 &&
            getLoadingFilter.catalogHeight.length > 0 &&
            getLoadingFilter.ages.length > 0
        ) {
            setLoadingFilter({
                catalogNationality: getLoadingFilter.catalogNationality,
                catalogCategory: getLoadingFilter.catalogCategory,
                catalogHeight: getLoadingFilter.catalogHeight,
                ages: getLoadingFilter.ages,
            })
            setSearchFilter({
                nationality: getLoadingFilter.catalogNationality[0].code,
                category: getLoadingFilter.catalogCategory[0].code,
                height: getLoadingFilter.catalogHeight[0].code,
                ageStart: getLoadingFilter.ages[0].code,
                ageEnd: getLoadingFilter.ages[0].code
            });
        }
    }

    const handleSelectChange = (field, value) => {

        setSearchFilter(prevState => ({
            ...prevState,
            [field]: value
        }))

        if (field === 'ageStart' && searchFilter.ageEnd !== null && value > searchFilter.ageEnd) {
            triggerAlert();
        }

        if (field === 'ageEnd' && searchFilter.ageStart !== null && value < searchFilter.ageStart) {
            triggerAlert();
        }
    }

    const triggerAlert = () => {
        setShowAlert(true);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            setShowAlert(false);
        }, config.autoHideDuration);
    }

    const handleButtonClick = (e) => {
        e.preventDefault();
        if (searchFilter.ageStart !== null && searchFilter.ageEnd !== null && searchFilter.ageStart > searchFilter.ageEnd) {
            triggerAlert();
            return;
        }
        navigate('/community', { state: { searchFilter: searchFilter } });
    }

    useEffect(() => {
        fetchStartValues();
    }, [countryCode])

    return (
        <Fragment>
            {loadingFilter.ages.length > 0 &&
                loadingFilter.catalogCategory.length > 0 &&
                loadingFilter.catalogHeight.length > 0 &&
                loadingFilter.catalogNationality.length > 0 &&
                (
                    <div className="widget search-widget">
                        <div className="widget-inner">
                            <div className="widget-title">
                                <h5>{title}</h5>
                            </div>
                            <div className="widget-content">
                                <p>{desc}</p>
                                <form onSubmit={handleButtonClick} className="banner-form">
                                    <div className="gender">
                                        <div className="custom-select right w-100">
                                            <SelectGeneric
                                                loadingDefault={loadingFilter.catalogNationality[0].code}
                                                options={loadingFilter.catalogNationality}
                                                onChange={(value) => handleSelectChange('nationality', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="person">
                                        <div className="custom-select right w-100">
                                            <SelectGeneric
                                                loadingDefault={loadingFilter.catalogCategory[0].code}
                                                options={loadingFilter.catalogCategory}
                                                onChange={(value) => handleSelectChange('category', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="city">
                                        <div className="custom-select right w-100">
                                            <SelectGeneric
                                                loadingDefault={loadingFilter.catalogHeight[0].code}
                                                options={loadingFilter.catalogHeight}
                                                onChange={(value) => handleSelectChange('height', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="age">
                                        <div
                                            className="right d-flex justify-content-between w-100">
                                            <div className="custom-select">
                                                <SelectGeneric
                                                    loadingDefault={loadingFilter.ages[0].code}
                                                    options={loadingFilter.ages}
                                                    onChange={(value) => handleSelectChange('ageStart', value)}
                                                />
                                            </div>
                                            <div className="custom-select">
                                                <SelectGeneric
                                                    loadingDefault={loadingFilter.ages[loadingFilter.ages.length - 1].code}
                                                    options={loadingFilter.ages}
                                                    onChange={(value) => handleSelectChange('ageEnd', value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button>{btnText}</button>
                                </form>
                                {showAlert && (
                                    <div className="col-12">
                                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                                            {ageValidationError}
                                        </Alert>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
        </Fragment>
    );
}

export default FilterSearch;