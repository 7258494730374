// Import Library
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Import Component
import Loading from '../component/loading/loading';
import CardProfile from "../component/section/cardprofile";

// Import Web Api
import posterServices from '../dataservices/posterServices'

// Import Hooks
import useGeolocation from '../hooks/useGeolocation';

// Import Animation
import animation from "../assets/animation.json";

const CommunityPage = () => {

    const location = useLocation();
    const { searchFilter } = location.state || {};
    const geolocation = useGeolocation()
    const [loading, setLoading] = useState(true);
    const [profiles, setProfiles] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(0);

    const fetchStartValues = async () => {
        setLoading(true)
        const getSearchFilter = await posterServices.getSearchFilter(searchFilter.nationality, searchFilter.category, searchFilter.height, searchFilter.ageStart, searchFilter.ageEnd, pageNumber, pageSize);
        setLoading(false)
        if (getSearchFilter && getSearchFilter.succeeded) {
            setProfiles(getSearchFilter.data)
            setPageNumber(getSearchFilter.pageNumber)
            setPageSize(getSearchFilter.pageSize)
            setTotalPages(getSearchFilter.totalPages)
        }
    }

    useEffect(() => {
        fetchStartValues();
    }, [])

    if (loading || geolocation.loading || !geolocation?.geolocation?.country_code3) {
        return <Loading animation={animation} />;
    }

    return (
        <CardProfile country={geolocation.geolocation.country} profiles={profiles} setPageNumber={setPageNumber} pageNumber={pageNumber} totalPages={totalPages} />
    )
}

export default CommunityPage;