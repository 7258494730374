// Import Library
import { useState, useEffect, Fragment, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

// Import Component
import SelectGeneric from "../select/select-generic";
import LoadingText from "../loading/loadingtext";

// Import Web Api
import posterServices from "../../dataservices/posterServices";

// Import Config
import config from "../../config";

const btnText = "Buscar";
const ageValidationError = "La edad mínima no puede ser mayor a la edad máxima.";

const AboutTwo = (props) => {

    const navigate = useNavigate();
    const { countryCode } = props;
    const [loadingFilter, setLoadingFilter] = useState({ catalogNationality: [], catalogCategory: [], catalogHeight: [], ages: [] });
    const [searchFilter, setSearchFilter] = useState({ nationality: null, category: null, height: null, ageStart: null, ageEnd: null });
    const [loading, setLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const timerRef = useRef(null);

    const handleSelectChange = (field, value) => {

        setSearchFilter(prevState => ({
            ...prevState,
            [field]: value
        }))

        if (field === 'ageStart' && searchFilter.ageEnd !== null && value > searchFilter.ageEnd) {
            triggerAlert();
        }

        if (field === 'ageEnd' && searchFilter.ageStart !== null && value < searchFilter.ageStart) {
            triggerAlert();
        }
    }

    const triggerAlert = () => {
        setShowAlert(true);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            setShowAlert(false);
        }, config.autoHideDuration);
    }

    const handleButtonClick = (e) => {
        e.preventDefault();
        if (searchFilter.ageStart !== null && searchFilter.ageEnd !== null && searchFilter.ageStart > searchFilter.ageEnd) {
            triggerAlert();
            return;
        }
        navigate('/community', { state: { searchFilter: searchFilter } });
    }

    const fetchStartValues = async () => {
        setLoading(true);
        const getLoadingFilter = await posterServices.getLoadingFilter(countryCode);
        if (!getLoadingFilter.code &&
            getLoadingFilter.catalogNationality.length > 0 &&
            getLoadingFilter.catalogCategory.length > 0 &&
            getLoadingFilter.catalogHeight.length > 0 &&
            getLoadingFilter.ages.length > 0
        ) {
            setLoadingFilter({
                catalogNationality: getLoadingFilter.catalogNationality,
                catalogCategory: getLoadingFilter.catalogCategory,
                catalogHeight: getLoadingFilter.catalogHeight,
                ages: getLoadingFilter.ages,
            })
            setSearchFilter({
                nationality: getLoadingFilter.catalogNationality[0].code,
                category: getLoadingFilter.catalogCategory[0].code,
                height: getLoadingFilter.catalogHeight[0].code,
                ageStart: getLoadingFilter.ages[0].code,
                ageEnd: getLoadingFilter.ages[0].code
            });
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchStartValues();
    }, [countryCode])

    return (
        <Fragment>
            {loading && (<LoadingText />)}
            {loadingFilter.ages.length > 0 &&
                loadingFilter.catalogCategory.length > 0 &&
                loadingFilter.catalogHeight.length > 0 &&
                loadingFilter.catalogNationality.length > 0 &&
                (
                    <div className="about about--style3 padding-top pt-xl-0">
                        <div className="container">
                            <div className="section__wrapper wow fadeInUp" data-wow-duration="1.5s">
                                <form onSubmit={handleButtonClick}>
                                    <div className="banner__list">
                                        <h5>Busca tu fantasía</h5>
                                        <div className="row g-4 align-items-center row-cols-xl-5 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                                            <div className="col">
                                                <label>Nacionalidad</label>
                                                <div className="banner__inputlist">
                                                    <SelectGeneric
                                                        loadingDefault={loadingFilter.catalogNationality[0].code}
                                                        options={loadingFilter.catalogNationality}
                                                        onChange={(value) => handleSelectChange('nationality', value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <label>Categoría</label>
                                                <div className="banner__inputlist">
                                                    <SelectGeneric
                                                        loadingDefault={loadingFilter.catalogCategory[0].code}
                                                        options={loadingFilter.catalogCategory}
                                                        onChange={(value) => handleSelectChange('category', value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <label>Estatura</label>
                                                <div className="banner__inputlist">
                                                    <SelectGeneric
                                                        loadingDefault={loadingFilter.catalogHeight[0].code}
                                                        options={loadingFilter.catalogHeight}
                                                        onChange={(value) => handleSelectChange('height', value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>Edad Min</label>
                                                        <div className="banner__inputlist">
                                                            <SelectGeneric
                                                                loadingDefault={loadingFilter.ages[0].code}
                                                                options={loadingFilter.ages}
                                                                onChange={(value) => handleSelectChange('ageStart', value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <label>Edad Max</label>
                                                        <div className="banner__inputlist">
                                                            <SelectGeneric
                                                                loadingDefault={loadingFilter.ages[loadingFilter.ages.length - 1].code}
                                                                options={loadingFilter.ages}
                                                                onChange={(value) => handleSelectChange('ageEnd', value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <button type="submit" className="lab-btn d-block"><span>{btnText}</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {showAlert && (
                                    <div className="col-12">
                                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                                            {ageValidationError}
                                        </Alert>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
        </Fragment>
    )
}

export default AboutTwo;