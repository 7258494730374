// Import Library
import { useState, useEffect } from 'react'

// Import Web Api
import externalServices from "../dataservices/externalServices";

const useGeolocation = () => {

    const [geolocation, setGeolocation] = useState(null)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            const localStorageGeolocation = window.localStorage.getItem('geolocation');
            if (localStorageGeolocation) {
                setGeolocation(JSON.parse(localStorageGeolocation));
            } else {
                const response = await externalServices.getGeolocation();
                if (!response.code) {
                    setGeolocation(response);
                    localStorage.setItem('geolocation', JSON.stringify(response));
                }
            }
            setLoading(false);
        }
        load()

        const handleStorageChange = () => {
            load();
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        }

    }, [])
    return { geolocation, loading }
}

export default useGeolocation