import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";

// Import Component
import LoadingText from "../loading/loadingtext";

// Import Web Api
import posterServices from '../../dataservices/posterServices'

//Import Config
import config from "../../config"

const title = "Mapa";
const desc = "";

const imgStyle = {
    width: '100px',
    height: '130px',
    objectFit: 'cover',
    borderRadius: '4px'
}

const marginBottomStyle = {
    marginBottom: '10px'
};

const flexRowCenterStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
};

const flexBoxStyle = {
    flex: '0 0 100px',
    paddingRight: '8px'
};

const widthStyle = {
    width: '300px'
};

const Map = (props) => {

    const mapRef = useRef();
    const boundsRef = useRef();
    const { countryCode } = props
    const [publisherCoordinates, setPublisherCoordinates] = useState([]);
    const [mapCenter, setMapCenter] = useState(null);
    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchStartValues = async () => {
        const responseGetCountry = await posterServices.getCoordinates(countryCode);
        if (!responseGetCountry.code && responseGetCountry.length > 0) {
            setPublisherCoordinates(responseGetCountry);
        }
        setLoading(false);
    }

    const calculateMapCenter = (data) => {
        if (data.length === 0) return;

        const bounds = new window.google.maps.LatLngBounds();
        data.forEach((item) => {
            const latLng = new window.google.maps.LatLng(
                parseFloat(item.coordinates.latitude),
                parseFloat(item.coordinates.longitude)
            );
            bounds.extend(latLng);
        });

        const center = bounds.getCenter();
        setMapCenter({ lat: center.lat(), lng: center.lng() });
        boundsRef.current = bounds;
    };

    const handleMapLoad = (map) => {
        mapRef.current = map;
        if (boundsRef.current) {
            mapRef.current.fitBounds(boundsRef.current);
        }
    };

    const getProfilePhoto = (photos) => {
        const item = Array.isArray(photos) ? photos.find(e => e.isProfilePhoto) : null;
        return item
            ? `${process.env.REACT_APP_URL_MEDIA}${item.path}/${item.fileName}`
            : `${process.env.PUBLIC_URL}/assets/images/logo/fondo_xugars_2.png`;
    }

    const onSelect = item => {
        setSelected(item);
    }

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAPS,
        libraries: config.googleMaps.places
    })

    useEffect(() => {
        if (isLoaded) {
            fetchStartValues();
        }
    }, [countryCode, isLoaded]);

    useEffect(() => {
        if (isLoaded && publisherCoordinates.length > 0) {
            calculateMapCenter(publisherCoordinates);
        }
    }, [isLoaded, publisherCoordinates]);

    if (loadError) return "Error loading maps";
    if (!isLoaded || loading) return <LoadingText />;

    return (
        <Fragment>
            <div className="about padding-tb">
                <div className="container">
                    <div className="section-header wow fadeInUp" data-wow-duration="1.5s">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    {publisherCoordinates.length > 0 && mapCenter && (
                        <div className="contact-bottom">
                            <div className="contac-bottom">
                                <div className="row justify-content-center g-0">
                                    <div className="col-12">
                                        <div className="location-map">
                                            <GoogleMap
                                                mapContainerStyle={config.googleMaps.containerStyle}
                                                center={mapCenter}
                                                zoom={parseInt(process.env.REACT_APP_MAP_ZOOM)}
                                                options={{
                                                    ...config.googleMaps.options,
                                                    scrollwheel: true,
                                                }}
                                                onLoad={handleMapLoad}
                                            >
                                                {publisherCoordinates.map((item, index) => (
                                                    <Marker
                                                        key={index}
                                                        position={{
                                                            lat: parseFloat(item.coordinates.latitude),
                                                            lng: parseFloat(item.coordinates.longitude),
                                                        }}
                                                        icon={{
                                                            url: `${process.env.PUBLIC_URL}/favicon.ico`,
                                                            scaledSize: new window.google.maps.Size(32, 32)
                                                        }}
                                                        onClick={() => onSelect(item)}
                                                    />
                                                ))}
                                                {selected && (
                                                    <InfoWindow
                                                        position={{
                                                            lat: parseFloat(selected.coordinates.latitude),
                                                            lng: parseFloat(selected.coordinates.longitude),
                                                        }}
                                                        clickable={true}
                                                        onCloseClick={() => setSelected(null)}
                                                    >
                                                        <div style={widthStyle}>
                                                            <div className="card shadow" style={flexRowCenterStyle}>
                                                                <div style={flexBoxStyle}>
                                                                    <Link to={`/profile?GuidPublisher=${selected.guidPublisher}`}>
                                                                        <img
                                                                            src={getProfilePhoto(selected.photos)}
                                                                            alt={`${selected.alias}`}
                                                                            className="card-img-left"
                                                                            style={imgStyle}
                                                                        />
                                                                    </Link>
                                                                </div>
                                                                <div className="right">
                                                                    <div style={marginBottomStyle}>
                                                                        <span>Nombre: {selected.alias}</span>
                                                                    </div>
                                                                    <div style={marginBottomStyle}>
                                                                        <span>Nacionalidad: {selected.nameNationality}</span>
                                                                    </div>
                                                                    <div style={marginBottomStyle}>
                                                                        <span>Edad: {new Date().getFullYear() - new Date(selected.birthDate).getFullYear()}</span>
                                                                    </div>
                                                                    <div >
                                                                        <span>Ciudad: {selected.geographicLocation2Name}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </InfoWindow>
                                                )}
                                            </GoogleMap>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default Map;
