import React, { useState } from 'react';

const SelectGeneric = ({ loadingDefault, options, onChange }) => {

    const [selectedValue, setSelectedValue] = useState(loadingDefault);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        onChange(event.target.value);
    }

    return (
        <select value={selectedValue} onChange={handleChange}>
            {options.map((value, index) => (
                <option key={index} value={value.code}>
                    {value.description}
                </option>
            ))}
        </select>
    )
}

export default SelectGeneric