// Import Library
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';

// Import Page
import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import CommunityPage from "./page/community";
import ContactPage from "./page/contact";
import ActiveGroup from "./page/active-group";
import HomeTwo from "./page/hometwo";
import Policy from "./page/policy";
import ProfilePage from "./page/profile";

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<HomeTwo />} />
				<Route path="community" element={<CommunityPage />} />
				<Route path="profile" element={<ProfilePage />} />
				<Route path="active-group" element={<ActiveGroup />} />
				<Route path="policy" element={<Policy />} />
				<Route path="*" element={<ErrorPage />} />
				<Route path="contact" element={<ContactPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;