// Import Library
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

// Import Web Api
import posterServices from '../../dataservices/posterServices'

const title = "Te podría gustar";

const baseStyle = {
    width: '108px',
    height: '108px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center'
}

const imgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
}

const LikeMember = (props) => {

    const { guidPublisher, countryCode } = props;
    const [topProfile, setTopProfile] = useState([]);

    const fetchStartValues = async () => {
        const getTop = await posterServices.getTop(countryCode);
        if (!getTop.code) {
            setTopProfile(getTop)
        }
    }

    const getProfilePhoto = (photos) => {
        const item = Array.isArray(photos) ? photos.find(e => e.isProfilePhoto) : null;
        return item
            ? `${process.env.REACT_APP_URL_MEDIA}${item.path}/${item.fileName}`
            : `${process.env.PUBLIC_URL}/assets/images/logo/fondo_xugars_2.png`;
    }

    const getImageThumbStyle = (photos) => {
        const hasProfilePhoto = Array.isArray(photos) && photos.some(e => e.isProfilePhoto);
        return {
            ...baseStyle,
            alignItems: hasProfilePhoto ? 'center' : 'normal',
        }
    }

    useEffect(() => {
        if (countryCode) {
            fetchStartValues();
        }
    }, [guidPublisher])

    return (
        <div className="widget like-member">
            <div className="widget-inner">
                <div className="widget-title">
                    <h5>{title}</h5>
                </div>
                <div className="widget-content">
                    <div className="row row-cols-3 row-cols-sm-auto g-3">
                        {topProfile.map((value, index) => (
                            <div className="col" key={index}>
                                <div className="image-thumb" style={getImageThumbStyle(value.photos)}>
                                    <Link to={`/profile?GuidPublisher=${value.guidPublisher}`}>
                                        <img
                                            src={getProfilePhoto(value.photos)}
                                            alt={`${value.alias}`}
                                            style={imgStyle}
                                        />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LikeMember;