// Import Styles
import "../../styles/loading.css";

const LoadingText = () => {

    return (
        <div className="loader-container">
            <div className="loader">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
            <div className="loading-text">Cargando...</div>
        </div>
    )
}

export default LoadingText